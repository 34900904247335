import React from 'react';
import './GameCard.css'
import { Link } from 'react-router-dom';

function GameCard({ data }) {
  return (
    <>
      <div className='gameCard'>
        <a href={`/game/${data.shortUrl}`}><img src={require(`../../assets/images/${data.logo}`)} alt={"Play poki games - " + data.title} /></a>
        <p className='text-center m-0 fs-14'>{data.title}</p>
      </div>
    </>
  )
}

export default GameCard