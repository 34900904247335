import React, { useState } from 'react'
import './Login.css';
import { useCookies } from 'react-cookie';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/firebase';

function Login() {
    const [cookies, setCookie] = useCookies(["user"]);
    const [formData, setFormData] = useState({ email: '', password: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            let loginData = await signInWithEmailAndPassword(auth ,formData.email, formData.password);
            setCookie("user", loginData, { path: "/" });
        } catch (error) {
          alert(error.message);
        }
      };

    return (
        <>
            <div className='login_page'>
                <div className='row Login_width'>
                    <div className='col-12 Login_main-section'>
                        <div className='Login_header'>
                            <h2>Login</h2>
                        </div>
                        <div className='login_section col-12'>
                            <form>
                                <div className='mt-4'>
                                    <input type="email" className='input' placeholder='Email' name='email' onChange={handleChange} />
                                </div>
                                <div className='mt-4'>
                                    <input type="password" className='input' placeholder='Password' name='password' onChange={handleChange} autoComplete="on" />
                                </div>
                                <div>
                                    <button type='submit' className='Login_button' onClick={handleLogin}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login