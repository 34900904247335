// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA3XozN2sXlyQgzl1GL4gp6Eq18Eu--KQQ",
  authDomain: "freeonlinegames-1b6e3.firebaseapp.com",
  databaseURL: "https://freeonlinegames-1b6e3-default-rtdb.firebaseio.com",
  projectId: "freeonlinegames-1b6e3",
  storageBucket: "freeonlinegames-1b6e3.appspot.com",
  messagingSenderId: "801606829944",
  appId: "1:801606829944:web:f2534cc1b60b74435fea8d",
  measurementId: "G-2S6EVNBP8C"
};

let app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
