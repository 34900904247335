import React, { useEffect } from "react";
import ViewMaster from "../../Components/ViewMaster/ViewMaster";

function About() {
  useEffect(() => {
    document.title = "Play Poki Games | About Us"
  }, [])
  return (
    <>
      <div>
        <div className="bgContent">
          <div className="container py-4 text-white fs-20">
            <div className="mb-4">
              <h4 className="DescriptionHeading"> About Play Poki Games </h4>
              <p>Play Poki Games is happy to have you aboard, as we aim to create the best browser-based gaming platform in the world. All we want is to make sure that players never have any trouble playing games and also to support all types of developers.</p>
              <p>Our games are playable at home, at school or on the move, if you are looking for a source of fun you can engage yourself in games that are playable on desktops, tablets and mobile phones. For millions of people around the world, Play Poki Games is their first choice when they feel like gaming online making it the ultimate playground. Moreover, the game is free and open to all.</p>
              <p>We are creators from diverse backgrounds as well as tech lovers; we also fall into different age groups and love playing video games.</p>
              <p>Instantly load your favorite games directly in your web browser and enjoy them today at any location.</p>
              <p>We invite you to come experience limitless gaming on devices ranging from PCs and laptops to Chromebooks, Apple's latest gadgets, and Android devices with us.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewMaster(About);
